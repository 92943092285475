import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api_getContentPageByCode } from '~/backend/api_calls';

import ContentPageMenuLinks from '~/components/common/ContentPageMenuLinks';

import NewsletterArea from '~/components/common/NewsletterArea';
import dropdown_down from '~/images/dropdown-down.svg';

export default function Footer() {
	const { t } = useTranslation();
	const [footerPaymentLogos, setFooterPaymentLogos] = useState({});
	const [customer, setCustomer] = useState({});
	const [footerInfo, setFooterInfo] = useState('');

	useEffect(() => {
		customer['at'] = true;
		setCustomer(customer);

		api_getContentPageByCode('footer_payment_logos').then(json => setFooterPaymentLogos(json));

		api_getContentPageByCode('footer-info').then(json => setFooterInfo(json));

		//add bestprice badge
		// Create script element
		const script = document.createElement('script');
		script.src = 'https://scripts.bestprice.gr/badge.js';
		script.async = true;

		// Append the script to a specific container
		const container = document.getElementById('bestprice-container');
		container?.appendChild(script);

		// Optional: Cleanup function to remove the script when the component unmounts
		return () => {
			container?.removeChild(script);
		};
	}, []);

	const AccordionToggle = event => {
		let current = event.currentTarget;
		current.classList.toggle('active');
	};

	return (
		<>
			<footer>
				<div id="footer-top">
					<div className="content-wrapper footer-level-1">
						<NewsletterArea />
						<div className="flex-col footer-clmns">
							<div className="footer-menu-clmn">
								<h3 className="footer-links-title">
									{t('service')}
									<div className="drop-icon" onClick={AccordionToggle}>
										<img src={dropdown_down} />
									</div>
								</h3>
								<div
									className="footer-info-section column-links"
									dangerouslySetInnerHTML={{
										__html: footerInfo.bd
									}}></div>
							</div>

							<div className="footer-menu-clmn">
								<h3 className="footer-links-title">
									{t('my_account_footer')}
									<div className="drop-icon" onClick={AccordionToggle}>
										<img src={dropdown_down} />
									</div>
								</h3>
								<ContentPageMenuLinks show_footer_1={true} />
							</div>

							<div className="footer-menu-clmn">
								<h3 className="footer-links-title">
									{t('useful_links')}
									<div className="drop-icon" onClick={AccordionToggle}>
										<img src={dropdown_down} />
									</div>
								</h3>
								<ContentPageMenuLinks show_footer_2={true} />
							</div>
						</div>
					</div>
				</div>

				<div className="footer-bottom-1">
					<div className="content-wrapper">
						<div
							className="payment-methods"
							dangerouslySetInnerHTML={{
								__html: footerPaymentLogos.bd
							}}></div>

						<div className="partners-area">
							<div className="partner-box">
								<div id="sa-badge-embedded-plugin"></div>
								<span id="bestprice-container">
									<noscript>
										<a href="https://www.bestprice.gr">BestPrice.gr</a>
									</noscript>
								</span>
							</div>
						</div>
					</div>
				</div>

				<div className="footer-bottom-2">
					<div className="content-wrapper">
						<div id="copyright">
							<span className="year">&copy; {new Date().getFullYear()} Undergroundshop.gr</span>
							All rights reserved.
						</div>

						<div className="dev-info">
							Designed & Developed by{' '}
							<a href="https://sleed.com/" rel="noreferrer" target="_blank">
								Sleed
							</a>
							.
						</div>

						<div className="bottom-menu">
							<a href="/pages/terms-of-use">{t('terms_of_use')}</a>
							<a href="/pages/privacy-policy">{t('privacy_policy')}</a>
						</div>
					</div>
				</div>
				{/*{process.env.NODE_ENV == 'production' && (*/}
				{/*	<MessengerCustomerChat*/}
				{/*		pageId={process.env.REACT_APP_FACEBOOK_CHAT_PAGE_ID}*/}
				{/*		appId={process.env.REACT_APP_FACEBOOK_CHAT_APP_ID}*/}
				{/*		htmlRef={window.location.pathname}*/}
				{/*	/>*/}
				{/*)}*/}
			</footer>
		</>
	);
}
