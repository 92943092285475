import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { api_getContentPageByCode, api_getMainMenu } from '~/backend/api_calls';
import { default as UserSession, default as UserState } from '~/backend/user_session';
import useWindowSize from '~/components/hooks/useWindowSize';
import MenuCloseIcon from '~/images/mobile-menu-close.svg';
import userIcon from '~/images/user-icon-mobile.svg';
import wishlistIcon from '~/images/wishlist-icon-mobile.svg';
import MenuItem from './MenuItem';

const MenuSection = ({ toggleMobileMenu }) => {
	const [menuItems, setMenuItems] = useState([]);
	const { width } = useWindowSize();
	const [t] = useTranslation();

	useEffect(() => {
		/*setLangChangeListener();*/

		api_getContentPageByCode('menu_section')
			.then(json => {
				// Apply login state
				if (json.bd != null) {
					if (!UserState.isAuthenticated()) {
						json.bd = json.bd.replace('{Sign-In-Link}', "<a href='/login'>Sign in</a>");
					} else {
						json.bd = json.bd.replace('{Sign-In-Link}', "<a href='/profile'>Profile</a>");
					}
				}

				api_getMainMenu()
					.then(json => setMenuItems(json.items))
					.catch(error => {
						console.error(error);
					});
			})
			.catch(error => {
				console.log(error);
			});
	}, []);

	const handleUserClick = () => {
		document.querySelector('#user-login a').click();
		toggleMobileMenu();
	};

	//const changeLanguage = () => {
	//	const oldLang = localStorage.getItem('I18N_LANGUAGE');
	//	const lang = localStorage.getItem('I18N_LANGUAGE') == 'el' ? 'en' : 'el';
	//	let { pathname } = location;

	//	i18n.changeLanguage(lang);
	//	localStorage.setItem('I18N_LANGUAGE', lang);

	//	// Update url
	//	var url = '';

	//	if (pathname == null || pathname == '') {
	//		pathname = '/';
	//	}
	//	if (pathname.length > 3 && !pathname.startsWith('/el/') && !pathname.startsWith('/en/')) {
	//		url = '/' + lang + pathname;
	//	} else {
	//		if (pathname.startsWith('/' + oldLang)) {
	//			url = pathname.replace('/' + oldLang, '/' + lang);
	//		} else {
	//			url = '/' + lang + pathname;
	//		}
	//	}

	//	window.location.href = url;
	//};

	return (
		<div id="menu-section">
			<div className="mobile-view-section">
				<div className="view-wishlist-mobile">
					{UserSession.isAuthenticated() ? (
						<Link to="/wishlists" className="active" onClick={toggleMobileMenu}>
							<img src={wishlistIcon} alt="WISHLIST" />
						</Link>
					) : (
						<Link to="/wishlist" className="active" onClick={toggleMobileMenu}>
							<img src={wishlistIcon} alt="WISHLIST" />
						</Link>
					)}
				</div>
				<div className="user-login-mobile" onClick={handleUserClick}>
					<img src={userIcon} alt="LOGIN" />
				</div>
				{/*<button*/}
				{/*	type="button"*/}
				{/*	id="lang-name"*/}
				{/*	className={localStorage.getItem('I18N_LANGUAGE') == 'el' ? 'lang-gr' : 'lang-en'}*/}
				{/*	onClick={() => changeLanguage()}>*/}
				{/*	{localStorage.getItem('I18N_LANGUAGE') == 'el' ? (*/}
				{/*		<>*/}
				{/*			{' '}*/}
				{/*			<img src={flagGr} /> <span>GR</span>{' '}*/}
				{/*		</>*/}
				{/*	) : (*/}
				{/*		<>*/}
				{/*			{' '}*/}
				{/*			<img src={flagEn} /> <span>EN</span>{' '}*/}
				{/*		</>*/}
				{/*	)}*/}
				{/*</button>*/}
				<div className="menu-close-mobile" onClick={() => toggleMobileMenu()}>
					<img src={MenuCloseIcon} alt="close-menu" />
				</div>
			</div>
			<nav>
				<div className={width <= 1420 ? 'mobile-main-menu' : 'main-menu'}>
					{menuItems
						.filter(x => !x.pid)
						.map((item, index) => (
							<MenuItem key={item.id} menuItem={item} index={index} menuItems={menuItems} onclick={toggleMobileMenu} />
						))}
				</div>
			</nav>
			<div className="mobile-view-section bottom">
				<Link to="/giftcard" onClick={() => toggleMobileMenu()}>
					{t('gift_card')}
				</Link>
				<Link to="/storefinder" onClick={() => toggleMobileMenu()}>
					{t('stores-top')}
				</Link>
				<Link to="/contact" onClick={() => toggleMobileMenu()}>
					<svg xmlns="http://www.w3.org/2000/svg" width="13.5" height="13.5" viewBox="0 0 13.5 13.5">
						<path
							id="message"
							d="M17,10.4a5.819,5.819,0,0,1-.625,2.639A5.9,5.9,0,0,1,11.1,16.306a5.819,5.819,0,0,1-2.639-.625L4.5,17l1.319-3.958A5.819,5.819,0,0,1,5.194,10.4,5.9,5.9,0,0,1,8.458,5.125,5.819,5.819,0,0,1,11.1,4.5h.347A5.889,5.889,0,0,1,17,10.056Z"
							transform="translate(-4 -4)"
							fill="none"
							stroke="#6b727d"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="1"
						/>
					</svg>
					{t('need_help')}
				</Link>
			</div>
		</div>
	);
};

MenuSection.propTypes = {
	toggleMobileMenu: PropTypes.any
};

export default MenuSection;
