import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { api_getProductDimensions } from '~/backend/api_calls';
import ShopCart from '~/backend/shop_cart';
import { formatAmountValue } from '~/common/helper';

import { useDispatch } from 'react-redux';
import { addToCartGTAG4Event, removeFromCartGTAG4Event } from '~/common/gtag4';
import { notifyError } from '~/components/common/ToastMessages';
import deleteProd from '~/images/delete.svg';
import plus_icon from '~/images/plus-icon.svg';
import minus_icon from '~/images/remove.svg';
import { headerCartQuantityAction, openCartWidgetAction } from '~/store/cart/actions.js';
// import { sendRemoveFromCartEvent } from "common/gtm.js";

const CartItem = ({ cartItem, setCartItems }) => {
	const { t } = useTranslation();
	const [color, setColor] = useState(null);
	const [dimension, setDimension] = useState(null);
	const defaultValue = {
		value: cartItem.quantity,
		label: `${cartItem.quantity}`
	};

	const [itemAmount, setItemAmount] = useState(defaultValue.value);
	const [maxQuantity, setMaxQuantity] = useState(0);
	const [errorMessage, setErrorMessage] = useState('');

	const dispatch = useDispatch();
	const closeCart = () => {
		document.body.classList.remove('cart-open');
		dispatch(openCartWidgetAction(false));
	};

	useEffect(() => {
		if (cartItem.dimension == 0) {
			if (cartItem.product.qty == undefined) {
				setMaxQuantity(0);
				return;
			}
			setMaxQuantity(cartItem.product.qty);
			return;
		}

		api_getProductDimensions(cartItem.product.id).then(json => {
			for (let i = 0; i < json.items.length; i++) {
				let item = json.items[i];
				if (item.id == cartItem.dimension || item.id == cartItem.dimension.id) {
					if (item.chex) {
						setColor(item);
					} else {
						setDimension(item);

						const maxquantity = item.qty !== undefined ? item.qty : cartItem.product.qty;
						if (maxquantity <= 0) {
							setErrorMessage(t('cart_item_error_sold_out'));
						} else if (cartItem.quantity > maxquantity) {
							setErrorMessage(t('cart_item_error_max_quantity') + maxquantity);
						} else {
							setErrorMessage('');
						}
						setMaxQuantity(maxquantity);

						let optionsArray = [];
						for (let i = 1; i <= maxQuantity; i++) {
							if (i <= 20) {
								optionsArray.push({ value: i, label: `${i}` });
							}
						}

						if (optionsArray.length === 0) {
							optionsArray.push({ value: 0, label: `0` });
						}
					}
				}
			}
		});
	}, []);

	const showPrice = () => {
		const price = cartItem.product.cprc;
		const priceBD = cartItem.product.cprcbd;

		if (priceBD) {
			return (
				<React.Fragment>
					{formatAmountValue(price)}
					<span>{formatAmountValue(priceBD)}</span>
				</React.Fragment>
			);
		} else {
			return <span className="single-price">{formatAmountValue(price)}</span>;
		}
	};

	const removeCartItem = () => {
		setCartItems(ShopCart.remove(cartItem));
		removeFromCartGTAG4Event({ ...cartItem.product, quantity: cartItem.quantity });
	};

	const handlePlus = () => {
		if (cartItem.quantity + 1 > maxQuantity) {
			notifyError(t('cart_item_error_max_quantity') + maxQuantity);
		} else {
			setErrorMessage('');
			setItemAmount(itemAmount + 1);
			cartItem.quantity++;
			addToCartGTAG4Event({ ...cartItem.product, quantity: 1 });
			setCartItems(ShopCart.changeQuantity(cartItem));
			dispatch(headerCartQuantityAction(ShopCart.getTotalQuantity()));
		}
	};

	const handleMinus = () => {
		if (cartItem.quantity == 1) {
			removeCartItem();
		} else {
			if (itemAmount - 1 <= maxQuantity) {
				setErrorMessage('');
			}

			setItemAmount(itemAmount - 1);
			cartItem.quantity--;
			removeFromCartGTAG4Event({ ...cartItem.product, quantity: 1 });
			setCartItems(ShopCart.changeQuantity(cartItem));
			dispatch(headerCartQuantityAction(ShopCart.getTotalQuantity()));
		}
	};

	return (
		<div className="cart-item flex-col">
			<Link to={cartItem.product.eurl} className="picture">
				<img onClick={() => closeCart()} src={dimension?.img1 ? dimension?.img1 : cartItem.product.img1} alt={cartItem.product.nm} />
			</Link>
			<div className="cart-item-info flex-col">
				<Link to={cartItem.product.eurl}>
					<h4 onClick={() => closeCart()}>{cartItem.product.nm}</h4>
				</Link>
				{color ? (
					<div className="color">
						{t('cart_item_color')}: <span>{color.cvtxt}</span>
					</div>
				) : null}
				{dimension ? (
					<div className="dimension">
						{t('cart_item_dimension')}: <span>{dimension.svtxt}</span>
					</div>
				) : null}

				<div className="edit-mobile flex-col">
					<div className="bottom-right">
						{/* <div className="price">{calculateProductPrice(cartItem.product)}</div> */}
						<div className="price">{showPrice()}</div>
						{errorMessage.length > 0 ? <div className="cart-item-error">{errorMessage}</div> : null}
					</div>
				</div>
			</div>
			<div className="quantity">
				<div className="controls">
					<div className="button minus-sign" onClick={handleMinus}>
						<img src={minus_icon} />
					</div>

					<div className="amount">{cartItem.quantity}</div>

					<div className="button plus-sign" onClick={handlePlus}>
						<img src={plus_icon} />
					</div>
				</div>
			</div>
			<div className="edit flex-col">
				<div className="bottom-right">
					{/* <div className="price">{calculateProductPrice(cartItem.product)}</div> */}
					<div className="price">{showPrice()}</div>
					{errorMessage.length > 0 ? <div className="cart-item-error">{errorMessage}</div> : null}
				</div>
				<button type="button" className="remove-cart-item" onClick={removeCartItem}>
					<img align="right" src={deleteProd} alt="delete-product" />
				</button>
			</div>
		</div>
	);
};
export default CartItem;
