import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UserSession from '~/backend/user_session';
import { validateGuestUserEmail } from '~/components/checkout/utils/checkout_step_1.js';
import { notifyError } from '~/components/common/ToastMessages.js';
import { showCheckoutModal } from '~/store/actions.js';

const RegisterSection = ({ setIsShownRegister }) => {
	const { t, i18n } = useTranslation();
	const [emailInput, setEmailInput] = useState('');

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [guestGender, setGuestGender] = useState('2');
	let guestEmail = React.createRef();

	const openRegister = event => {
		setIsShownRegister(current => !current);
		/*        setShowGuest(false);*/
	};

	const handleGenderChange = e => {
		setGuestGender(e.target.value);
	};

	const handleFastCheckout = e => {
		e.preventDefault();

		if (!validateGuestUserEmail(guestEmail.current.value)) {
			notifyError(t('validation_email'));
			return;
		}

		UserSession.setEmail(guestEmail.current.value);

		UserSession.setGender(guestGender);

		dispatch(showCheckoutModal(false));
		navigate('/checkout-step-2');
	};

	return (
		<>
			<div className="register-wrapper">
				<div className="grid-col">
					<div className="first-col-reg">
						<form onSubmit={handleFastCheckout}>
							<div className={'form-input text-label ' + emailInput}>
								<input
									type="email"
									required
									name="guest-email"
									ref={guestEmail}
									onChange={e => setEmailInput('actived')}
									placeholder="email"
								/>
								<label htmlFor="guest-email">Email*</label>
							</div>
							<div className="grid-col gender-sec radio">
								<label className="radio-label" htmlFor="woman">
									<input type="radio" id="woman" name="sex" value="2" checked={guestGender === '2'} onChange={e => handleGenderChange(e)} />{' '}
									{t('gender_woman')}
								</label>
								<label className="radio-label" htmlFor="man">
									<input type="radio" id="man" name="sex" value="1" checked={guestGender === '1'} onChange={e => handleGenderChange(e)} />{' '}
									{t('gender_man')}
								</label>
								<label className="radio-label" htmlFor="other">
									<input type="radio" id="other" name="sex" value="3" checked={guestGender === '3'} onChange={e => handleGenderChange(e)} />{' '}
									{t('gender_other')}
								</label>
								<label className="radio-label" htmlFor="noDisclose">
									<input
										type="radio"
										id="noDisclose"
										name="sex"
										value="4"
										checked={guestGender === '4'}
										onChange={e => handleGenderChange(e)}
									/>{' '}
									{t('gender_rather_not_say')}
								</label>
							</div>
							<button type="submit" className="submit arrow-button white-arrow-button">
								{t('register_guest')}
							</button>
						</form>
					</div>
					<div className="sec-col-reg grid-col">
						<div dangerouslySetInnerHTML={{ __html: t('register_info') }}></div>
						<button type="button" onClick={openRegister} className="submit arrow-button black-arrow-button">
							{t('create_an_account')}
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

RegisterSection.propTypes = {
	setIsShownRegister: PropTypes.any
};

export default RegisterSection;
